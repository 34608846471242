
    .createStepSucceed{
        .createStepSucceed-icon{
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            i{
                font-size: 52px;
                color: #20D28C;
            }
            span{
                color: #343434;
                font-size: 24px;
                font-weight: bold;
            }
        }
        .getBack{
            display: flex;
            justify-content: center;
            padding-top: 400px;
            .getBackBtn{
                width: 100px;
                height: 30px;
                background: #ffffff;
                border-color: #1222D8;
                color: #1222D8;
                line-height: 0;
                border-radius: 2px;
            }
        }
    }
